import { RadioGroup, RadioGroupDataInterface } from '@systemeio/ui-shared'
import { EmailTemplateItemInterface } from 'modules/email/types/email-template-interface'
import { useEffect } from 'react'
import { SuccessButton } from 'shared/components/button'
import { Loader } from 'shared/components/loader'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import CheckCircleIcon from 'shared/icons/check-circle-icon'

export interface EmailTemplateSelectorProps {
  useNewEditor: boolean
  templateId?: number
  onChange: (value: { useNewEditor: boolean; template?: number }) => void
  templates: EmailTemplateItemInterface[] | undefined
}

const EmailTemplateSelector = ({
  onChange,
  useNewEditor,
  templateId,
  templates,
}: EmailTemplateSelectorProps) => {
  const { t } = useLocoTranslation()

  const templateTypeData: RadioGroupDataInterface<string>[] = [
    { id: 'new', caption: t('dashboard.email_template_selector.template_visual_editor') },
    { id: 'old', caption: t('dashboard.email_template_selector.template_classic_editor') },
  ]

  useEffect(() => {
    if (templates) {
      onChange({ useNewEditor: useNewEditor, template: templates[0].id })
    }
  }, [templates])

  return (
    <>
      <div className="w-full h-fit">
        <RadioGroup
          options={templateTypeData}
          selected={useNewEditor ? 'new' : 'old'}
          onChange={useNewEditor => {
            if (useNewEditor === 'old') {
              onChange({ useNewEditor: false })
            } else onChange({ template: templateId, useNewEditor: true })
          }}
        />
      </div>
      <div className={`overflow-auto`}>
        <ul
          className={`flex gap-5 flex-wrap flex-col sm:flex-row items-center justify-center pb-5`}
        >
          {useNewEditor
            ? templates?.map((template, idx) => {
                return (
                  <li key={idx}>
                    <div
                      className={`relative overflow-hidden justify-start group flex flex-col items-center w-[230px] h-[250px] sm:w-[230px] sm:h-[260px] border-2 border-transparent relative shadow-lg rounded-lg outline-none focus-visible:border-blue ${
                        template.id === templateId && 'border-green'
                      } main-transition-colors`}
                    >
                      <div
                        className={`w-full h-full z-1 rounded-lg bg-white/70 ${
                          templateId === template.id ? '' : 'hidden group-hover:flex'
                        } flex flex-col gap-5 justify-center items-center justify-self-stretch`}
                      >
                        {template.id === templateId ? (
                          <div className={`flex gap-1 max-w-[180px] items-center h-[40px]`}>
                            <CheckCircleIcon className={'fill-green'} />
                            <span className={'text-green break-all font-bold'}>
                              {t('dashboard.email_template_selector.template_selected')}
                            </span>
                          </div>
                        ) : (
                          <SuccessButton
                            onClick={e => {
                              e.preventDefault()
                              onChange({ useNewEditor, template: template.id })
                            }}
                            className={'max-w-[180px]'}
                          >
                            <CheckCircleIcon className={'fill-white w-[20px] h-[20px]'} />
                            <span className={'text-white break-words'}>
                              {t('dashboard.email_template_selector.select_template')}
                            </span>
                          </SuccessButton>
                        )}
                      </div>
                      <img
                        src={template.previewUrl || ''}
                        className={`absolute rounded-lg h-full object-cover`}
                      />
                    </div>
                  </li>
                )
              }) || <Loader className="mx-auto" />
            : undefined}
        </ul>
      </div>
    </>
  )
}

export default EmailTemplateSelector
