import { createLecture } from 'modules/courses/courses/curriculum/api/lecture-api'
import { useLectureTemplate } from 'modules/courses/courses/curriculum/hooks/use-lecture-templates'
import { CourseCurriculumInterface } from 'modules/courses/courses/curriculum/types/curriculum-interface'
import {
  CreateLectureErrorsInterface,
  CreateLectureInterface,
  LectureTemplateInterface,
} from 'modules/courses/courses/curriculum/types/lecture-interface'
import { nonDraggableProps } from 'modules/courses/courses/curriculum/utils/non-draggable-props'
import React, { memo, useEffect, useState } from 'react'
import { PrimaryButton } from 'shared/components/button'
import { SuccessButton } from 'shared/components/button'
import { Checkbox } from 'shared/components/checkbox'
import CreateModal from 'shared/components/create-modal'
import FormInput from 'shared/components/form-input'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from 'shared/components/form/field-error-and-description'
import { FieldLabel, FieldLabelProps } from 'shared/components/form/field-label'
import { Loader } from 'shared/components/loader'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import AddIcon from 'shared/icons/add-icon'
import CheckCircleIcon from 'shared/icons/check-circle-icon'
import EyeIcon from 'shared/icons/eye-icon'
import { fetchWithFieldErrors } from 'shared/utils/fetch-with-errors'
import { KeyedMutator } from 'swr'

const defaultErrors: CreateLectureErrorsInterface = {
  fields: {
    name: '',
    pageTemplate: '',
    delayBeforePreviousLecture: '',
  },
}
const defaultValues: CreateLectureInterface = {
  name: '',
  delayBeforePreviousLecture: 0,
  showComments: false,
  pageTemplate: undefined,
}

interface CreateLectureModalInterface {
  moduleId: number
  mutate: KeyedMutator<CourseCurriculumInterface>
}

const CreateLectureModal = ({ moduleId, mutate }: CreateLectureModalInterface) => {
  const { t } = useLocoTranslation()
  const [opened, setOpened] = useState(false)
  const [errors, setErrors] = useState(defaultErrors)

  const afterLeave = () => {
    setErrors(defaultErrors)
  }

  const onClose = () => setOpened(false)

  const onCreate = async (data: CreateLectureInterface) => {
    const lectureData = {
      ...data,
      delayBeforePreviousLecture: data.delayBeforePreviousLecture
        ? data.delayBeforePreviousLecture
        : 0,
    }
    await fetchWithFieldErrors(async () => {
      const response = await createLecture(moduleId, lectureData)
      await mutate(data => {
        if (data) {
          return {
            ...data,
            modules: data.modules.map(module => {
              if (module.id === moduleId) {
                return { ...module, lectures: [...module.lectures, response.data] }
              }
              return module
            }),
          }
        }
      }, false)
    }, setErrors)
  }

  return (
    <div {...nonDraggableProps}>
      <div className="flex align-center justify-center">
        <PrimaryButton
          onClick={() => setOpened(true)}
          isFetching={false}
          disabled={false}
          data-test={'module-create-lecture'}
        >
          <AddIcon />
          <span>{t('dashboard.course.add_lecture_modal.trigger_title')}</span>
        </PrimaryButton>
      </div>
      <CreateModal
        opened={opened}
        onClose={onClose}
        afterLeave={afterLeave}
        defaultValues={defaultValues}
        fullCaption={t('dashboard.course.lecture.create')}
        caption={t('dashboard.course.lecture_title')}
        creatable={[
          {
            field: 'name',
            onRender: (value, onChange) => (
              <FormInput
                key={`${moduleId}-name`}
                label={t('global.name')}
                value={value}
                required
                onChange={e => onChange(e.target.value)}
                error={errors.fields.name}
                data-test={'module-create-lecture-name'}
              />
            ),
          },
          {
            field: 'delayBeforePreviousLecture',
            onRender: (value, onChange) => (
              <FormInput
                key={`${moduleId}-delayBeforePreviousLecture`}
                label={t('dashboard.course.lecture_delay_title')}
                value={value}
                type="number"
                onChange={e => onChange(Number(e.target.value))}
                error={errors.fields.delayBeforePreviousLecture}
                data-test={'module-create-lecture-delay'}
              />
            ),
          },
          {
            field: 'showComments',
            onRender: (value, onChange) => (
              <Checkbox
                key={`${moduleId}-showComments`}
                label={t('dashboard.course.show_comments_title')}
                value={value}
                onChange={onChange}
                data-test={'module-create-lecture-showComments'}
              />
            ),
          },
          {
            field: 'pageTemplate',
            onRender: (value, onChange) => (
              <TemplateSelector
                key={`${moduleId}-pageTemplate`}
                label={t('dashboard.course.lecture.select_template_title')}
                value={value}
                onChange={onChange}
                error={errors.fields.pageTemplate}
                data-test={'module-create-lecture-pageTemplate'}
              />
            ),
          },
        ]}
        onCreate={onCreate}
        requiredFields={['name', 'pageTemplate']}
        dataTestKey={'module-create-lecture'}
      />
    </div>
  )
}

type TemplateSelectorProps = FieldErrorAndDescriptionProps &
  FieldLabelProps & {
    value?: LectureTemplateInterface['id']
    onChange: (id: LectureTemplateInterface['id']) => void
  }

const TemplateSelector = ({
  value,
  onChange,
  error,
  label,
  required = true,
}: TemplateSelectorProps) => {
  const { t } = useLocoTranslation()
  const { data: templates } = useLectureTemplate()

  useEffect(() => {
    if (templates && templates[0]) {
      onChange(templates[0].id)
    }
  }, [templates])

  return (
    <>
      {templates ? (
        templates.length === 0 ? (
          <span className={'flex justify-center'}>
            {t('dashboard.course.lecture.templates_not_found')}
          </span>
        ) : (
          <div className="flex flex-col gap-1">
            <FieldLabel label={label} required={required} />
            <div className={`overflow-auto`}>
              <ul
                className={`flex gap-5 flex-wrap flex-col sm:flex-row items-center justify-center pb-5`}
              >
                {templates?.map(template => {
                  return (
                    <li key={template.id}>
                      <div
                        className={`relative overflow-hidden justify-start group flex flex-col items-center w-[230px] h-[250px] sm:w-[230px] sm:h-[260px] border-2 border-transparent shadow-lg rounded-lg outline-none focus-visible:border-blue ${
                          template.id === value && 'border-green'
                        } main-transition-colors`}
                      >
                        <div
                          className={`w-full h-full z-1 rounded-lg bg-white/70 ${
                            value === template.id ? '' : 'hidden group-hover:flex'
                          } flex flex-col gap-5 justify-center items-center`}
                        >
                          <PrimaryButton
                            href={`/page/template/${template.id}/show`}
                            target={'_blank'}
                            className={`max-w-[180px] break-all`}
                            type="button"
                            data-test={'module-create-lecture-showTemplate'}
                          >
                            <EyeIcon className={`w-[20px] h-[20px] fill-white`} />
                            {t('dashboard.course.lecture.preview_template')}
                          </PrimaryButton>
                          {template.id === value ? (
                            <div className={`flex gap-1 max-w-[180px] items-center h-[40px]`}>
                              <CheckCircleIcon className={'fill-green'} />
                              <span className={'text-green break-all font-bold'}>
                                {t('dashboard.course.lecture.template_selected')}
                              </span>
                            </div>
                          ) : (
                            <SuccessButton
                              onClick={e => {
                                e.preventDefault()
                                onChange(template.id)
                              }}
                              className={'max-w-[180px]'}
                              type="button"
                            >
                              <CheckCircleIcon className={'fill-white w-[20px] h-[20px]'} />
                              <span className={'text-white break-all'}>
                                {t('dashboard.course.lecture.select_template')}
                              </span>
                            </SuccessButton>
                          )}
                        </div>
                        <img src={template.previewImage || ''} className={`absolute rounded-lg`} />
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )
      ) : (
        <div className={'flex justify-center'}>
          <Loader />
        </div>
      )}
      <FieldErrorAndDescription error={error} errorClassName={'text-center'} />
    </>
  )
}

export default memo(CreateLectureModal)
