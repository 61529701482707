import {
  CourseCreateInterface,
  CourseEditApiInterface,
  CourseEditInterface,
  CourseInterface,
} from 'modules/courses/courses/types/course-interface'
import {
  ReminderEditInterface,
  ReminderEmailInterface,
  ReminderInterface,
} from 'modules/courses/courses/types/reminder-interface'
import httpClient from 'shared/utils/http-client'

export const COURSES_API = '/api/dashboard/customer/membership/courses'

export const duplicateCourse = (id: number) =>
  httpClient.post<CourseInterface>(`${COURSES_API}/${id}/duplicate`)

export const removeCourse = (id: number) => httpClient.delete(`${COURSES_API}/${id}`)

export const activateCourse = (update: 'deactivate' | 'activate', id: number) =>
  httpClient.post<CourseInterface>(`${COURSES_API}/${id}/${update}`)

export const updateCourse = (data: CourseEditInterface, id: number) =>
  httpClient.put<CourseEditApiInterface>(`${COURSES_API}/${id}`, data)

export const createCourse = (data: Partial<CourseCreateInterface>) =>
  httpClient.post<CourseCreateInterface, CourseInterface>(COURSES_API, data)

export const enableReminder = (id: number) =>
  httpClient.post(`${COURSES_API}/${id}/reminder-settings/enable`)

export const disableReminder = (id: number) =>
  httpClient.post(`${COURSES_API}/${id}/reminder-settings/disable`)

export const editReminderSettings = (id: number, data: ReminderEditInterface) =>
  httpClient.put<ReminderEditInterface, ReminderInterface>(
    `${COURSES_API}/${id}/reminder-settings`,
    data,
  )

export const createReminderEmail = (id: number, data: ReminderEmailInterface) =>
  httpClient.post<ReminderEmailInterface>(`${COURSES_API}/${id}/reminder-settings/email`, data)

export const editReminderEmail = (id: number, data: ReminderEmailInterface) =>
  httpClient.put<ReminderEmailInterface>(`${COURSES_API}/${id}/reminder-settings/email`, data)
